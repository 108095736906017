import './index.css'
import './pointer.css'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'

let scrollY = window.scrollY;
let posv = window.innerHeight*1.5

let mobile = 0;
if(window.innerWidth<900){
    mobile=1;
}else{
    mobile=0;
}

import CANNON from 'cannon'
import { CubeCamera } from 'three'

var mouse = {x: 0, y: 0};
document.addEventListener('mousemove', onMouseMove, false);
document.addEventListener('mousedown', onMouseDown, false);

const loadingBarElement = document.querySelector('.loadingBar')
const loadText = document.getElementById('loadText')


//loaders

const loadingManager = new THREE.LoadingManager(

    () =>
    {
        console.log('loaded')
    },

    (itemUrl, itemsLoaded, itemsTotal) =>
    {
        const progressRatio = itemsLoaded / itemsTotal
        loadingBarElement.style.transform = `scaleX(${progressRatio})`
        loadText.innerHTML = Math.round(progressRatio*100)+'%';
        console.log(itemUrl)
    }

)


/**
 * Base
 */
// Debug


// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

const textureLoader = new THREE.TextureLoader(loadingManager)
const particleTexture = textureLoader.load('./models/itw/particle.png')

/**
 * Models
 */
let loaded = 0;
const dracoLoader = new DRACOLoader(loadingManager)
dracoLoader.setDecoderPath('./draco/')

const gltfLoader = new GLTFLoader(loadingManager)
gltfLoader.setDRACOLoader(dracoLoader)




/**Physics */
//World
const world = new CANNON.World()
let bodies = []
const sphereShape = new CANNON.Sphere(0.53)
const bigSphereShape = new CANNON.Sphere(2)
const sfera = new CANNON.Body({
    mass: 100,
    position: new CANNON.Vec3(0, 0, 6),
    shape: bigSphereShape
})
sfera.quaternion.x = 1
//objects

const group = new THREE.Group()
scene.add(group)

world.addBody(sfera)

const material = new THREE.MeshStandardMaterial({
    metalness:0.3,
    roughness:0.4,
})

const sferaV = new THREE.Mesh(
    new THREE.SphereGeometry(3),
    material
)

const sferaT = new THREE.Mesh(
    new THREE.SphereGeometry(2),
    material
)
sferaT.position.x=-20;

const sferaT2 = new THREE.Mesh(
    new THREE.SphereGeometry(2),
    material
)
sferaT2.position.x=-20;

scene.add(sferaT, sferaT2)







let objects = []
let objectsPos = []
let objectsRot = []
let illustration;


gltfLoader.load(
    "./models/Hero/Hero.glb",
    (gltf) =>
    {   


        for(let i=0; i<gltf.scene.children.length; i++){
            objects[i] = gltf.scene.children[i]
            objectsPos[i] = new THREE.Vector3(objects[i].position.x, objects[i].position.y, objects[i].position.z)
            objectsRot[i] = new THREE.Vector3(objects[i].quaternion.x, objects[i].quaternion.y, objects[i].quaternion.z, objects[i].quaternion.w)
        }

        group.add(gltf.scene)
        illustration=gltf.scene;

        for(let i=0; i<objects.length; i++){

            bodies[i]=new CANNON.Body({
                mass: 1,
                position: objectsPos[i],
                shape: sphereShape
            })
            bodies[i].position.x=bodies[i].position.x+0
            if(mobile==0){
                bodies[i].position.y=bodies[i].position.y-0.7
            }else{
                bodies[i].position.y=bodies[i].position.y-4
            }
            
            bodies[i].position.z=bodies[i].position.z+0
    
            world.addBody(bodies[i])
        
        }

        loaded=1;
        

    }
)
let oikia;
let tjt3d;
let flow;
let intertwined;
let particles;
let aria;
let pastachute;

if(mobile==0){
    
gltfLoader.load(
    "./models/oikia2/oikia.glb", //"/models/oikia2/oikia.glb"
    (gltf) =>
    {   
        oikia = gltf.scene.children[0]
        oikia.scale.set(0.5,0.5,0.5)
        oikia.rotation.set(Math.PI/2,0,3.5)
        oikia.position.y=0.5;
        oikia.position.x=-20;
        scene.add(oikia)
        loaded++;

    }
)



gltfLoader.load(
    "./models/tjt/tjt.glb", //"/models/tjt/tjt.glb
    (gltf) =>
    {   
        tjt3d = gltf.scene.children[0]
        tjt3d.scale.set(1.8,1.8,1.8)
        tjt3d.position.y=1;
        tjt3d.position.x=-20;
        tjt3d.rotation.set(Math.PI/2,0,3.5)
        scene.add(tjt3d)
        loaded++;

    }
)



gltfLoader.load(
    "./models/flow/flow.glb", //"/models/tjt/tjt.glb
    (gltf) =>
    {   
        flow = gltf.scene.children[0]
        flow.scale.set(62,62,62)
        flow.position.x=-20;
        flow.position.y=1;
        flow.rotation.set(0,-0.5,0)
        scene.add(flow)
        loaded++;

    }
)



gltfLoader.load(
    "./models/itw/intertwined.glb", //"/models/tjt/tjt.glb
    (gltf) =>
    {   
        intertwined = gltf.scene.children[0].geometry

        const particlesMaterial = new THREE.PointsMaterial()
        particlesMaterial.size=0.04
        particlesMaterial.color= new THREE.Color("#ffaa44")
        particlesMaterial.sizeAttenuation=true
        //particlesMaterial.map = particleTexture
        particles = new THREE.Points(intertwined, particlesMaterial)
        particles.scale.set(1.3,1.3,1.3)
        particles.position.x=-20;
        particles.position.y=-1.7;
        scene.add(particles)
        loaded++;

    }
)



gltfLoader.load(
    "./models/aria/aria.glb", //"/models/tjt/tjt.glb
    (gltf) =>
    {   
        aria = gltf.scene.children[0]
        aria.scale.set(2.5,2.5,2.5)
        aria.position.x=-20;
        aria.position.y=1;
        aria.rotation.set(-Math.PI/2,Math.PI,0.1)
        scene.add(aria)
        loaded++;

    }
)



gltfLoader.load(
    "./models/pastachute/pastachute.glb", //"/models/tjt/tjt.glb
    (gltf) =>
    {   
        pastachute = gltf.scene.children[0]
        pastachute.scale.set(16.5,16.5,16.5)
        pastachute.position.x=-20;
        pastachute.position.y=1;
        pastachute.rotation.set(-Math.PI/2,-0.5,-0.1)
        scene.add(pastachute)
        loaded++;

    }
)


}

function checkLoad(){
    if(mobile==0){
    if(particles && flow && tjt3d && aria && illustration && pastachute && oikia)
    {return true}
    else{return false}
    }else{
        return true
    }
}










/**
 * Lights
 */

const ambientLight = new THREE.AmbientLight(0xffffff, 0.0)

const pointLight1 = new THREE.PointLight(0xffffff, 3)
pointLight1.position.set(3, 0, -7)

const pointLight2 = new THREE.PointLight(0xffffff, 10)
pointLight2.position.set(2, 0, 5)

const rectAreaLight = new THREE.RectAreaLight(0xffffff, 7,15,15)
rectAreaLight.position.set(-5, 7, 0)
rectAreaLight.rotation.set(0, 3*Math.PI/2, 0)

const pointLight3 = new THREE.PointLight(0xffffff, 8)
pointLight3.position.set(-5, 7, 0)

group.add(ambientLight, pointLight1, pointLight2, rectAreaLight, pointLight3)



function getHeight(){
    if(window.innerWidth>1600 && window.innerHeight>900){
        return window.innerHeight;
    }else if(window.innerWidth>1600 && window.innerHeight<900){
        return 900;
    }else if(window.innerWidth<1600 && window.innerWidth>1200){
        return 900;
    }else if(window.innerWidth>900){
        return window.innerWidth-300;
    }else if(window.innerWidth<900){
        return 600;
    }
}
function getWidth(){
    if(window.innerWidth<900){
        //console.log(innerWidth)
        if(screen.width*0.9>window.innerWidth){
        return window.innerWidth
        }else{
            return screen.width
        }
        
    }else{
        return window.innerWidth/2
    }
}


/**
 * Sizes
 */
const sizes = {
    width: getWidth(),
    height: getHeight()
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = getWidth()
    sizes.height = getHeight()
    if(window.innerWidth<900){
        mobile=1;
        createCards()
    }else{
        if(mobile==1){location.reload()}
        mobile=0;
        removeCards()
    }
    if(checkLoad()){
        moveWebgl()
    }
    
    
    

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(60, sizes.width / sizes.height, 0.1, 100)
camera.position.set(0, 0, -10)
camera.rotation.set(0,Math.PI,0)
scene.add(camera)

group.rotation.set(0,Math.PI/2,0)

// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    alpha: true
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.physicallyCorrectLights = true;
renderer.outputEncoding = THREE.sRGBEncoding


let hover1=0;
let hover2=0;
let hover3=0;
let hover4=0;
let hover5=0;
let hover6=0;
let hover7=0;

var tjtText = document.getElementById("tjt");

var tjt = document.getElementById("project1");
var flw = document.getElementById("project2");
var itw = document.getElementById("project3");
var ara = document.getElementById("project4");
var pct = document.getElementById("project5");
var oka = document.getElementById("project6");
var ase = document.getElementById("project7");

tjt.addEventListener("mouseover", function() { hover1 = 1 })
tjt.addEventListener("mouseout", function() { hover1 = 0 })

flw.addEventListener("mouseover", function() { hover2 = 1 })
flw.addEventListener("mouseout", function() { hover2 = 0 })

itw.addEventListener("mouseover", function() { hover3 = 1 })
itw.addEventListener("mouseout", function() { hover3 = 0 })

ara.addEventListener("mouseover", function() { hover4 = 1 })
ara.addEventListener("mouseout", function() { hover4 = 0 })

pct.addEventListener("mouseover", function() { hover5 = 1 })
pct.addEventListener("mouseout", function() { hover5 = 0 })

oka.addEventListener("mouseover", function() { hover6 = 1 })
oka.addEventListener("mouseout", function() { hover6 = 0 })

ase.addEventListener("mouseover", function() { hover7 = 1 })
ase.addEventListener("mouseout", function() { hover7 = 0 })



function createTag(name, progetto){
    const projectTag = document.createElement("div");
    projectTag.className = "projectTag";
    projectTag.innerHTML = name;
    progetto.appendChild(projectTag);
  }

  createTag('UX/UI', document.getElementById('project1'))
  createTag('UX/UI', document.getElementById('project2'))
  createTag('PRODUCT', document.getElementById('project2'))
  createTag('VR/AR', document.getElementById('project3'))
  createTag('VISUAL', document.getElementById('project3'))
  createTag('UX/UI', document.getElementById('project4'))
  createTag('UX/UI', document.getElementById('project5'))
  createTag('VISUAL', document.getElementById('project5'))
  createTag('UX/UI', document.getElementById('project6'))
  createTag('PRODUCT', document.getElementById('project6'))


  function createCard(title, tags, i, j){

    const cardSpan = document.createElement("span");
    cardSpan.className = "cardSpan";

    const card = document.createElement("div");
    card.className = "card hoverable";
    cardSpan.appendChild(card);
    switch(title) {
        case "THE JOURNALIST'S THREAD":
            card.onclick = function() {window.location.href = './tjt.html'}
          break;
        case "ARIA":
            card.onclick = function() {window.location.href = './aria.html'}
          break;
        case "IO APP":
            card.onclick = function() {window.location.href = './io.html'}
          break;
        case "OIKIA":
            card.onclick = function() {window.location.href = './oikia.html'}
          break;
        case "FLOW":
            card.onclick = function() {window.location.href = './flow.html'}
          break;
        case "ASPIRE":
            card.onclick = function() {window.location.href = './aspire.html'}
          break;
        case "INTERTWINED":
            card.onclick = function() {window.location.href = './intertwined.html'}
          break;
        case "EMA":
            card.onclick = function() {window.location.href = './ema.html'}
          break;
        case "PASTACHUTE":
            card.onclick = function() {window.location.href = './pastachute.html'}
          break;
        case "ARTÙ":
            card.onclick = function() {window.location.href = './artu.html'}
          break;
        case "DOLOMITI":
            card.onclick = function() {window.location.href = './dolomiti.html'}
          break;
          
        default:
            card.onclick = function() {window.location.href = './tjt.html'}
      }

    
    card.style.animation='swift-box 0.6s forwards ease-in-out 0.'+ j+'s'

    const thumbnail = document.createElement("div");
    thumbnail.className = "thumbnail";
    card.appendChild(thumbnail);

    const thumbnail2 = document.createElement("div");
    thumbnail2.className = "thumbnail-back";
    thumbnail2.style.backgroundImage = "url('img/"+i+".1.png')"
    thumbnail.appendChild(thumbnail2);

    const thumbnail1 = document.createElement("div");
    thumbnail1.className = "thumbnail-front";
    thumbnail1.id = j;
    thumbnail1.style.backgroundImage = "url('img/"+i+".0.png')"
    if(mobile==1){
        thumbnail1.style.opacity= '0'
    }
    thumbnail.appendChild(thumbnail1);

    const projectName = document.createElement("h2");
    projectName.className = "projectName";
    projectName.innerHTML = title;
    card.appendChild(projectName);

    const hr = document.createElement("hr");
    card.appendChild(hr);

    for(let i=0; i<tags.length; i++){
        createTag(tags[i], card)
    }

    document.getElementById("projects").appendChild(cardSpan);
    
  }

  let projects = []
  let cardsNumber = 6

projects[0] = {
    name:"JOURNALIST'S THREAD",
    tags:['UX/UI'],
    img:["tjt"]
}

projects[1] = {
    name:"FLOW",
    tags:['UX/UI', 'PRODUCT'],
    img:["flow"]
}

projects[2] = {
    name:"INTERTWINED",
    tags:['VISUAL', 'VR/AR'],
    img:["itw"]
}

projects[3] = {
    name:"ARIA",
    tags:['UX/UI'],
    img:["aria"]
}

projects[4] = {
    name:"PASTACHUTE",
    tags:['VISUAL'],
    img:["pastachute"]
}

projects[5] = {
    name:"OIKIA",
    tags:['UX/UI', 'PRODUCT'],
    img:["oikia"]
}

function createCards(){
   
if(mobile==1 && !document.getElementById('0')){
    for(let i=0; i<projects.length; i++){
            createCard(projects[i].name, projects[i].tags, projects[i].img, i);
    }
    createSeeAllButton()
}

}

createCards()


function createSeeAllButton(){
    const seeAllButton = document.createElement("div");
    seeAllButton.className = "seeAllButton";
    seeAllButton.id = "seeAllButton";
    seeAllButton.innerHTML = 'See All Projects →';
    seeAllButton.onclick = function() {window.location.href = './work.html'}
    document.getElementById("projects").appendChild(seeAllButton);
  }



  

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{

    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime
    //console.log(loaded)

    //update physics
    world.step(1 / 60, deltaTime, 3)
    

    //update objects
    
    
    if(checkLoad()){
        moveWebgl()
        document.getElementById('html').style.overflowY='scroll'
        if(mobile==1){
            document.getElementById("loadOverlay").style.animation='loadAnim 0.8s forwards ease-out 0.6s';
        }else{
            document.getElementById("loadOverlay").style.animation='loadAnim 0.5s forwards ease-out 0s';
        }
        
        document.getElementById('word-1').style.animation= '0.7s swift-text ease-in-out forwards';
        document.getElementById('word-2').style.animation= '0.7s swift-text ease-in-out forwards 0.2s';
        document.getElementById('word-2.1').style.animation= '0.7s swift-text ease-in-out forwards 0.2s';
        document.getElementById('word-3').style.animation= '0.6s swift-text ease-in-out forwards 0.4s';

        document.getElementById('word-4').style.animation= '0.6s swift-text ease-in-out forwards 0.6s';
        document.getElementById('word-5').style.animation= '0.6s swift-text ease-in-out forwards 0.65s';
        document.getElementById('word-6').style.animation= '0.6s swift-text ease-in-out forwards 0.70s';

if(mobile==0){
    scene.traverse(obj => obj.frustumCulled = false);
    particles.rotation.set(0.1*Math.sin(elapsedTime*1),0.1*Math.sin(elapsedTime*0.7),0.1*Math.sin(elapsedTime*1.3))
    oikia.rotation.set(0.3*Math.sin(elapsedTime*0.4)+Math.PI/2,0.3*Math.sin(elapsedTime*0.7),0.3*Math.sin(elapsedTime*0.6)+3.5)
    flow.rotation.set(0.3*Math.sin(elapsedTime*0.4),0.3*Math.sin(elapsedTime*0.7)-0.5,0.3*Math.sin(elapsedTime*0.6))
    tjt3d.rotation.set(0.1*Math.sin(elapsedTime*1)+1.5,0.1*Math.sin(elapsedTime*0.7),0.1*Math.sin(elapsedTime*1.3)+3.5)
    aria.rotation.set(0.1*Math.sin(elapsedTime*1)-Math.PI/2,0.1*Math.sin(elapsedTime*0.7)+Math.PI,0.1*Math.sin(elapsedTime*1.3)+0.1)
    pastachute.rotation.set(0.1*Math.sin(elapsedTime*1)-Math.PI/2,0.1*Math.sin(elapsedTime*0.7)-0.5,0.1*Math.sin(elapsedTime*1.3)-0.1)
}

    }

    if(checkLoad() && mobile==0){
    if(hover1==1){
        moveIn(tjt3d)
    }else{moveOut(tjt3d)}

    if(hover2==1){
        moveIn(flow)
    }else{moveOut(flow)}

    if(hover3==1){
        moveIn(particles)
    }else{moveOut(particles)}

    if(hover4==1){
        moveIn(aria)
    }else{moveOut(aria)}

    if(hover5==1){
        moveIn(pastachute)
    }else{moveOut(pastachute)}

    if(hover6==1){
        moveIn(oikia)
    }else{moveOut(oikia)}
}
    
    //sfera.applyLocalForce(new CANNON.Vec3(0, 0, -100),new CANNON.Vec3(0, 0, 0))
    sferaV.position.copy(sfera.position)
    sferaV.quaternion.copy(sfera.quaternion)

    //animate
    if(checkLoad()){
        for(let i=0; i<objects.length; i++){
            objects[i].position.copy(bodies[i].position)
            objects[i].quaternion.copy(bodies[i].quaternion)
            magnet(bodies[i], objectsPos[i])
        }
        sfera.velocity=new CANNON.Vec3(0,0,0)
        sfera.angularVelocity=new CANNON.Vec3(0,0,0,0)
        
    }


    
    

    // Update controls
    //controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()



function magnet(object, originalPos){
    object.applyImpulse(new CANNON.Vec3((-object.position.x + originalPos.x)/2, (-object.position.y + originalPos.y)/2, (-object.position.z + originalPos.z)/2), new CANNON.Vec3(0,0,0))
    object.velocity = new CANNON.Vec3(object.velocity.x*0.92,object.velocity.y*0.92,object.velocity.z*0.92)
    object.angularVelocity = new CANNON.Vec3(object.angularVelocity.x*0.4,object.angularVelocity.y*0.4,object.angularVelocity.z*0.4,object.angularVelocity.w*0.4)
}

function explode(){
    for(let i=0; i<objects.length; i++){
        inverseMagnet(bodies[i], objectsPos[i])
    }
}
const intensity=15;
function inverseMagnet(object, originalPos){
    object.applyImpulse(new CANNON.Vec3((object.position.x + originalPos.x)*intensity*Math.random(), (object.position.y + originalPos.y)*intensity*Math.random(), (object.position.z + originalPos.z)*intensity*Math.random()), new CANNON.Vec3(0,0.1,0))
}

function onMouseMove(event) {

	// Update the mouse variable
	event.preventDefault();
    
    if(mobile==0){
        mouse.x = (event.clientX / window.innerWidth) * 4 - 3;
    }else{
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    }
	
	mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;

 // Make the sphere follow the mouse
  var vector = new THREE.Vector3(mouse.x, mouse.y, 0.5);
	vector.unproject( camera );
	var dir = vector.sub( camera.position ).normalize();
	var distance = - camera.position.z / dir.z;
	var pos = camera.position.clone().add( dir.multiplyScalar( distance ) );
    
	
    sfera.position.x = 0
    sfera.position.y = pos.y
    sfera.position.z = pos.x
    
    
  
	// Make the sphere follow the mouse
//	mouseMesh.position.set(event.clientX, event.clientY, 0);
};

function onMouseDown(event){
    explode()
    mainBlue = getComputedStyle(document.documentElement).getPropertyValue('--main-blue');
    mainBlack = getComputedStyle(document.documentElement).getPropertyValue('--main-black');
    mainWhite = getComputedStyle(document.documentElement).getPropertyValue('--main-white');
};







//DOM STUFF BELOW
var words= []
var flagProjects=0;

window.onload = function() {
    words[0] = document.getElementById("myskills"); 
    words[1] = document.getElementById("myprojects"); 
    words[2] = document.getElementById("footer1"); 
    checkVisibility();
    checkVisibility2();
    checkVisibility3();
   }


window.addEventListener('scroll', function (event) {
    checkVisibility()
    checkVisibility2()
}, false);



function checkVisibility(){
    for(let i=0; i<words.length; i++){

        if (isInViewport(words[i])) {
            if(i==0){
                document.getElementById("word2").style.animation='swift-ptext 0.8s forwards ease-in-out 0s';
                document.getElementById("word1").style.animation='swift-ptext 0.8s forwards ease-in-out 0s';
                document.getElementById("skill-1").style.animation='swift-box 1s forwards ease-in-out 0s';
                document.getElementById("skill-2").style.animation='swift-box 1s forwards ease-in-out 0.1s';
                document.getElementById("skill-3").style.animation='swift-box 1s forwards ease-in-out 0.2s';
            }
            if(i==1){

                if(flagProjects==0){
                    flagProjects=1;
                document.getElementById("word3").style.animation='swift-ptext 0.6s forwards ease-in-out';
                document.getElementById("word4").style.animation='swift-ptext 0.6s forwards ease-in-out';

                document.getElementById("project1").className = 'projectActivated';
                document.getElementById("project2").className = 'projectActivated';                
                document.getElementById("project3").className = 'projectActivated';
                document.getElementById("project4").className = 'projectActivated';    
                document.getElementById("project5").className = 'projectActivated';
                document.getElementById("project6").className = 'projectActivated';
                document.getElementById("project7").className = 'projectActivated';
                document.getElementById("project1").style.transitionDelay = '0s';
                document.getElementById("project2").style.transitionDelay = '0.3s';
                document.getElementById("project3").style.transitionDelay = '0.6s';
                document.getElementById("project4").style.transitionDelay = '0.9s';
                document.getElementById("project5").style.transitionDelay = '1.2s';
                document.getElementById("project6").style.transitionDelay = '1.5s';
                document.getElementById("project7").style.transitionDelay = '1.8s';

                
                
                }
            }
            if(i==2){
                document.getElementById("word5").style.animation='swift-text 0.6s forwards ease-in-out 0.3s';
                document.getElementById("word6").style.animation='swift-text 0.6s forwards ease-in-out 0.5s';
                document.getElementById("word7").style.animation='swift-text 0.6s forwards ease-in-out 0.7s';
                document.getElementById("word8").style.animation='swift-text 0.6s forwards ease-in-out 0.9s';
                document.getElementById("word9").style.animation='swift-text 0.6s forwards ease-in-out 1.1s';
                document.getElementById("word10").style.animation='swift-ptext 0.4s forwards ease-in-out 1.2s';
                document.getElementById("word11").style.animation='swift-ptext 0.4s forwards ease-in-out 1.3s';
                document.getElementById("word12").style.animation='swift-ptext 0.4s forwards ease-in-out 1.4s';
                document.getElementById("word13").style.animation='swift-ptext 0.4s forwards ease-in-out 1.5s';
            }
        }

    }

}

function noDelay(value){
    value.style.transitionDelay = '0s';
}

var isInViewport = function (elem) {
    var bounding = elem.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.top <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right-10 <= (window.innerWidth || document.documentElement.clientWidth)
    );
};



init_pointer({
  pointerColor: "#000",
  ringSize: getRingSize(),
  ringClickSize: 10
})




function getRingSize(){
if(mobile==0){
    return 25;
}else{
    return 0;
}
}


var project1 = document.getElementById("project1");
var project2 = document.getElementById("project2");
var project3 = document.getElementById("project3");
var project4 = document.getElementById("project4");
var project5 = document.getElementById("project5");
var project6 = document.getElementById("project6");
var project7 = document.getElementById("project7");

if(mobile==1){
    tjtText.innerHTML = "JOURNALIST'S T.";
    project1.style.transitionDelay = '0s';
    project2.style.transitionDelay = '0s';
    project3.style.transitionDelay = '0s';
    project4.style.transitionDelay = '0s';
    project5.style.transitionDelay = '0s';
    project6.style.transitionDelay = '0s';
    project7.style.transitionDelay = '0s';
}


var bulbI = 0;
var myHoverInterval = null;
var bulb = document.getElementById("bulb");

function bulbAnim(){

    switch (bulbI) {
        case 0:
            bulb.innerHTML = "Ā";
          break;
        case 1:
            bulb.innerHTML = "ā";
          break;
        case 2:
            bulb.innerHTML = "Ă";
          break;
        case 3:
            bulb.innerHTML = "ă";
            break;
        case 4:
            bulb.innerHTML = "Ą";
          break;
      }

      if(bulbI>5){bulbI=0;}else{bulbI++;}
}

let mainBlue = getComputedStyle(document.documentElement).getPropertyValue('--main-blue');
let mainBlack = getComputedStyle(document.documentElement).getPropertyValue('--main-black');
let mainWhite = getComputedStyle(document.documentElement).getPropertyValue('--main-white');


bulb.addEventListener("mouseover", function() {
    if (myHoverInterval != null) {
        return;
    }
    myHoverInterval = setInterval(function() {
        bulbAnim()
        bulbText.style.color=mainBlue
    }, 100);
});

bulb.addEventListener("mouseout", function() {
    if (myHoverInterval != null) {
        clearInterval(myHoverInterval);
        myHoverInterval = null;
        bulb.innerHTML = "Ā";
        bulbText.style.color=mainWhite
    }
});

bulbText.addEventListener("mouseover", function() {
    if (myHoverInterval != null) {
        return;
    }
    myHoverInterval = setInterval(function() {
        bulbAnim()
        bulbText.style.color=mainBlue
    }, 100);
});

bulbText.addEventListener("mouseout", function() {
    if (myHoverInterval != null) {
        clearInterval(myHoverInterval);
        myHoverInterval = null;
        bulb.innerHTML = "Ā";
        bulbText.style.color=mainWhite
    }
});

var eyeI = 0;
var eye = document.getElementById("eye");

function eyeAnim(){

    switch (eyeI) {
        case 0:
            eye.innerHTML = "Đ";
          break;
        case 1:
            eye.innerHTML = "đ";
          break;
        case 2:
            eye.innerHTML = "Ē";
          break;
        case 3:
            eye.innerHTML = "ē";
            break;
        case 4:
            eye.innerHTML = "Ĕ";
          break;
        case 5:
            eye.innerHTML = "ē";
          break;
        case 6:
            eye.innerHTML = "Ē";
          break;
        case 7:
            eye.innerHTML = "đ";
          break;
        case 8:
            eye.innerHTML = "Đ";
          break;
      }

      if(eyeI>12){eyeI=0;}else{eyeI++;}
}

eye.addEventListener("mouseover", function() {
    if (myHoverInterval != null) {
        return;
    }
    myHoverInterval = setInterval(function() {
        eyeAnim()
        eyeText.style.color=mainBlue
    }, 100);
});

eye.addEventListener("mouseout", function() {
    if (myHoverInterval != null) {
        clearInterval(myHoverInterval);
        myHoverInterval = null;
        eye.innerHTML = "Đ";
        eyeText.style.color=mainWhite
    }
});

eyeText.addEventListener("mouseover", function() {
    if (myHoverInterval != null) {
        return;
    }
    myHoverInterval = setInterval(function() {
        eyeAnim()
        eyeText.style.color=mainBlue
    }, 80);
});

eyeText.addEventListener("mouseout", function() {
    if (myHoverInterval != null) {
        clearInterval(myHoverInterval);
        myHoverInterval = null;
        eye.innerHTML = "Đ";
        eyeText.style.color=mainWhite
    }
});


var dudeI = 0;
var myHoverInterval = null;
var dude = document.getElementById("dude");
var dudeText = document.getElementById("dudeText");

function dudeAnim(){

    switch (dudeI) {
        case 0:
            dude.innerHTML = "ġ";
          break;
        case 1:
            dude.innerHTML = "ġ";
          break;
        case 2:
            dude.innerHTML = "Ģ";
          break;
        case 3:
            dude.innerHTML = "Ġ";
          break;
        case 4:
            dude.innerHTML = "ģ";
            break;
        case 5:
            dude.innerHTML = "Ĥ";
          break;
        case 6:
            dude.innerHTML = "Ġ";
          break;
      }

      if(dudeI>6){dudeI=0;}else{dudeI++;}
}

dude.addEventListener("mouseover", function() {
    if (myHoverInterval != null) {
        return;
    }
    myHoverInterval = setInterval(function() {
        dudeAnim()
        dudeText.style.color=mainBlue
    }, 150);
});

dude.addEventListener("mouseout", function() {
    if (myHoverInterval != null) {
        clearInterval(myHoverInterval);
        myHoverInterval = null;
        dude.innerHTML = "Ġ";
        dudeText.style.color=mainWhite
    }
});

dudeText.addEventListener("mouseover", function() {
    if (myHoverInterval != null) {
        return;
    }
    myHoverInterval = setInterval(function() {
        dudeAnim()
        dudeText.style.color=mainBlue
    }, 150);
});

dudeText.addEventListener("mouseout", function() {
    if (myHoverInterval != null) {
        clearInterval(myHoverInterval);
        myHoverInterval = null;
        dude.innerHTML = "Ġ";
        dudeText.style.color=mainWhite
    }
});



window.addEventListener('scroll', () =>
{
    if(checkLoad()){
    moveWebgl()
    }
})




function moveWebgl(){
    
    scrollY = window.scrollY;


    posv = document.getElementById('word4').getBoundingClientRect().top+scrollY;
    posv=posv.toString()
    posv=posv+'px'

    if(scrollY>window.innerHeight && mobile==0){
        illustration.position.y=100;
        ambientLight.intensity=0.8;
        if(window.innerWidth<1200){
            document.getElementById('webgl').style.marginTop = '0px'
            document.getElementById('webgl').style.top = posv
            document.getElementById('webgl').style.zIndex = '110'
        }else{
            document.getElementById('webgl').style.top = posv
            document.getElementById('webgl').style.zIndex = '110'
            document.getElementById('webgl').style.marginTop = '0px'
        }
    }else if(mobile==0){
        illustration.position.y=0;
        ambientLight.intensity=0;
        pastachute.position.x=-20
        oikia.position.x=-20
        tjt3d.position.x=-20
        flow.position.x=-20
        aria.position.x=-20
        particles.position.x=-20
        document.getElementById('webgl').style.zIndex = '0'
        if(window.innerWidth<1200){
            document.getElementById('webgl').style.marginTop = '0px'
            document.getElementById('webgl').style.top = '0vw'
        }else{
            document.getElementById('webgl').style.top = '10px'
            document.getElementById('webgl').style.marginTop = '0px'
        }
        
    }else{
        document.getElementById('webgl').style.top = '75vw'
    }
}

function moveOut(object){
    if(object.position.x>-20 && checkLoad()){
        object.position.x-=0.7
        object.position.z+=0.2
    }
}
function moveIn(object){
    if(object.position.x<0){
        object.position.z =0
        object.position.x = (object.position.x + 0)/1.2
    }
}







//////////////////////////////////////////////////






function checkVisibility2(){
    for(let i = 0; i<12; i++){
        if(document.getElementById(i)){
        if (mobile==1 && isInViewport2(document.getElementById(i))) {
            document.getElementById(i).style.opacity='1'
            
        }else if (mobile==1){
            document.getElementById(i).style.opacity='0'
        }
    }
}
}

function checkVisibility3(){
    if (mobile==1){
    for(let i = 0; i<cardsNumber; i++){
        if(document.getElementById(i)){
            document.getElementById(i).style.opacity='0'
        }
        }
    }
    }
    
    var isInViewport2 = function (elem) {
        var bounding = elem.getBoundingClientRect();
        return (
            bounding.bottom >= 0 &&
            bounding.left >= -10 &&
            bounding.bottom +150  <= (window.innerHeight || document.documentElement.clientHeight) &&
            bounding.right-10 <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };
    
    function foo() {
    
        checkVisibility2();
    
        setTimeout(foo, 200);
    }
    
    foo();

    function removeCards(){
        console.log('removing')

        if(document.getElementById('seeAllButton')){
            let seeAllButton = document.getElementById('seeAllButton');
            seeAllButton.remove()
        }

        if(document.getElementsByClassName('card')){
            let paras = document.getElementsByClassName('card');
            while(paras[0]) {
                paras[0].parentNode.removeChild(paras[0])
            }
        }
    
        
    }

